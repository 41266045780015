import React from 'react';
import './Footer.css'; // Assuming the styles are shared
import logo from '../threpoly-logo-dark.svg';
import linkedIn from '../Images/linkedin.png';
import facebook from '../Images/facebook.png';
import twitter from '../Images/twitter.png';

const Footer = () => {
  return (
    <footer className="footer">
     <div className='container'>
      <div className="footer-grid" style={{ gridTemplateColumns: '1fr 1fr 1fr' }}>
        {/* Column 1: Threpoly Logo, Social Icons, Company Info */}
        <div className="footer-column">
          <div className="logo">
            {/* Replace with Threpoly Logo */}
            <img src={logo} className="logo" alt="Threpoly Logo" />
          </div>
          <br/>
          <br/>
          <p>Threpoly Limited is Registered in England and Wales under company number 15787809.</p>

          <p> <br/>167-169 Great Portland Street
          <br/>5th Floor
          <br/>London
          <br/>W1W 5PF
          </p>
          <br/>
          <div className="social-icons">
            {/* Add your social media icons */}
            <a href="https://www.linkedin.com/company/threpoly" target='_blank'><img src={linkedIn} className="social-icon" alt="LinkedIn Icon" /></a>
            <a href="/"><img src={facebook} className="social-icon" alt="Facebook Icon" /></a>
            <a href="/"><img src={twitter} className="social-icon" alt="Twitter Icon" /></a>
          </div>
        </div>

        {/* Column 2: Legal Links */}
        <div className="footer-column">
          <h3>Legals</h3>
            <br/>
            <br/>
            <p><a href="/downloads/Threpoly_Sales%20Terms%20and%20EULA_v1%202024.pdf" target='_Blank'>Sales Terms & Conditions</a></p>
            <p><a href="/downloads/Threpoly_Service%20Agreement_v1%202024.pdf" target='_Blank'>Service Agreement</a></p>
            <p><a href="/downloads/Threpoly_Privacy%20Policy_v1%202024.pdf" target='_Blank'>Privacy Policy</a></p>
        </div>

        {/* Column 3: Empty for now */}
        <div className="footer-column">
          {/* This column is intentionally left blank */}
        </div>
      </div>
      <p className='copyright'>Copyright © 2024 Threpoly Limited. All Rights Reserved.</p>
      </div>
    </footer>
  );
};

export default Footer;
