import React from 'react';
import './Company.css';

const Company = () => {


    return (
        <div className="container">
            <h1 className='page-title' style={{paddingTop: '16rem', color: 'var(--text-color', marginTop: '0'}}>Threpoly keeps watch, <span style={{color: 'var(--secondary-color'}}>so you don't have to.</span></h1>
            <div className='company-info-container'>
                <div className='company-graphic'>
                    <div className='company-graphic-box-wrapper'>
                        <div className='company-graphic-box-dash-dark'></div>
                        <div className='company-graphic-box'></div>
                        <div className='company-graphic-box-dash-light'></div>
                    </div>
                    <div className='company-graphic-line'></div>
                    <div className='company-graphic-box-wrapper'>
                        <div className='company-graphic-box-dash-dark'></div>
                        <div className='company-graphic-box'></div>
                        <div className='company-graphic-box-dash-light'></div>
                    </div>
                </div>
                <div className='company-info-text'>
                    <h1 className='page-title' style={{textAlign: 'left'}}>Where it started...</h1>
                    <br/>
                    <p className='company-page-sub-text'>Three of our founders have dedicated much of their careers to the file transfer industry, eventually crossing paths as part of this journey.</p>

                    <p className='company-page-sub-text'>Having worked with small local government authorities, large financial enterprises and everything in between, installing and maintaining file transfer and data exchange across the globe - we have seen first-hand the challenges which come with ensuring optimal services and the increasing reliance of trading availability.</p>

                    <p className='company-page-sub-text'>The challenges we saw when working with our customers; our 35+ years of collective experience in this industry; and our passion for problem solving led us to create Threpoly, the World’s first and only dedicated file transfer and data exchange performance and security monitoring solution.</p>

                    <p className='company-page-sub-text'>Threpoly was founded on the fundamental principle that it is not a cog in the machine of your operations, it is the oil which makes those cogs perform their best and to your expectations.
                    </p>
                    <br/>
                    <h1 className='page-title' style={{textAlign: 'left'}}>Today</h1>
                    <br/>
                    <p className='company-page-sub-text'>Although Threpoly is the new kid on the block, Threpoly is being used by a major retailer headquartered in the US; and has a comprehensive and innovative roadmap which its founders, customers and prospects have collaborated to help shape.</p>
                </div>
            </div>

        </div>
    );
};

export default Company;
