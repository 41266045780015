import React, { useState, useEffect } from 'react';
import './Pricing.css';

const Pricing = () => {
    const [isMonthly, setIsMonthly] = useState(false);
    const [mftServices, setMftServices] = useState('');
    const [protocols, setProtocols] = useState('');
    const [regions, setRegions] = useState('');
    const [connectionCredits, setConnectionCredits] = useState(0);
    const [currency, setCurrency] = useState('GBP'); 
    const [hoveredTooltip, setHoveredTooltip] = useState(null); // State to track hovered info symbol

    const exchangeRates = {
        GBP: 1, 
        USD: 1.35,
        EUR: 1.24,
        CHF: 1.17
    };

    const togglePricing = () => {
        setIsMonthly(!isMonthly);
    };

    const handleCurrencyChange = (e) => {
        setCurrency(e.target.value);
    };

    useEffect(() => {
        const mft = parseInt(mftServices, 10) || 0;
        const region = parseInt(regions, 10) || 0;
        const protocol = parseInt(protocols, 10) || 0;
        setConnectionCredits((mft * region) * protocol);
    }, [mftServices, regions, protocols]);

    const pricingData = [
        {
            tier: 'Starter',
            annualPrice: 81,
            monthlyPrice: 97,
            connections: [
                'Monitor: SFTP',
                'On: 1 x Service Address',
                'From: 1 x Cloud-Based Monitoring Agent (Choose from US, EUR, APAC)',
            ],
            features: [
                '1 x User Account',
                '24hrs of Retained Monitoring History',
                '3 Global Regions (US, EU, APAC).',
                'Outage Alerting via Email',
                'Access to Support',
                'Documentation and KBs',
            ],
        },
        {
            tier: 'Professional',
            annualPrice: 327,
            monthlyPrice: 394,
            connections: [
                'Monitor: SFTP & FTPS',
                'On: up-to 2 x Service Addresses',
                'From: up-to 2 x Cloud-Based Monitoring Agents (Choose from US, EUR, APAC)',
            ],
            features: [
                'Up-to 3 x User Accounts',
                'Role-Based Access Control',
                'Single Sign-On (SSO)',
                '90 Days of Retained Monitoring History',
                'Outage & Conditional Alerting via Email, Microsoft Teams and WhatsApp',
                'Access to Support',
                'Documentation and KBs',
                'Custom Alerting via Email and SMS',
                'Email-Based Technical Support',
            ],
        },
        {
            tier: 'Premium',
            annualPrice: 489,
            monthlyPrice: 587,
            connections: [
                'Monitor: SFTP & FTPS',
                'On: up-to 3 x Service Addresses',
                'From: up-to 3 x Cloud-Based Monitoring Agents (Choose from US, EUR, APAC)',
            ],
            features: [
                'Up to 10 x User Accounts',
                'Role-Based Access Control',
                'Single Sign-On (SSO).',
                '365 Days of Retained Monitoring History',
                'Outage, Slow-Down & Conditional Alerting via Email, Microsoft Teams, WhatsApp and REST API',
                'Exportable Reports with Custom Branding',
                'Access to Support',
                'Documentation and KBs',
                'Email-Based Technical Support',
            ],
        },
    ];

    const getCardClass = (plan) => {
        let className = `${plan.tier.toLowerCase()}-card`;

        if (connectionCredits > 1 && plan.tier === 'Starter') {
            className += ' not-selected';
        } else if (connectionCredits > 8 && plan.tier === 'Professional') {
            className += ' not-selected';
        }

        return className;
    };

    const resetCalc = () => {
        setMftServices('');
        setRegions('');
        setProtocols('');
    };

    const convertPrice = (price) => {
        return (price * exchangeRates[currency]).toFixed(0);
    };

    const getFormattedPrice = (price) => {
        const formattedPrice = convertPrice(price);
        switch (currency) {
            case 'GBP':
                return `£${formattedPrice}`;
            case 'USD':
                return `$${formattedPrice}`;
            case 'EUR':
                return `${formattedPrice}€`;
                case 'CHF':
                    return `Fr.${formattedPrice}`;
            default:
                return `${formattedPrice}`;
        }
    };

    const tooltips = {
        mftServices: "MFT Services are the number of installed instanced of the MFT service to be tested.",
        regions: "The number of regions the services will be tested from. A single MFT service can and should be tested from multiple origin regions.",
        protocols: "The number of file transfer protocols (SFTP, FTPS, etc.) that will be tested on each MFT service, from each origin region.",
    };

    return (
        <div className='container' style={{ backgroundColor: 'var(--secondary-color)', maxWidth: '100%' }}>
            <div className="pricing-container">
                <h1 className='page-title'>Pricing plans</h1>
                <p className='page-sub-heading'>Secure and Monitor your Critical Infrastructure Today or <a href="/temp-pricing">try our FREE 14 day trial</a>.</p>

                <div className="switch-container">
                    <span className='switch-payment' onClick={togglePricing}>{!isMonthly ? 'Switch to Pay Monthly' : ' Switch to Pay Annually (Up-to 20% discount)'}</span>

                    <select value={currency} onChange={handleCurrencyChange} className="currency-dropdown">
                        <option value="GBP">£GBP</option>
                        <option value="USD">$USD</option>
                        <option value="EUR">€EUR</option>
                        <option value="CHF">CHF</option>
                    </select>
                </div>

                <div className="pricing-cards">
                    {pricingData.map((plan, index) => (
                        <div key={index} className={`pricing-card ${getCardClass(plan)}`}>
                            <h2>{plan.tier}</h2>
                            {!isMonthly ?
                            <div className="old-price-wrapper">
                                <p className='was-text'>WAS</p>
                                <p className={plan.tier === 'Professional' ? 'old-price-number-dark' : 'old-price-number'}>
                                    {getFormattedPrice(isMonthly ? plan.annualPrice : plan.monthlyPrice)}
                                </p>
                            </div>
                            : null}
                            <div className="price-wrapper">
                                <p className='price-number'>
                                    {getFormattedPrice(!isMonthly ? plan.annualPrice : plan.monthlyPrice)}
                                </p>
                                {!isMonthly ? (
                                    <p className='per-month'>Per Month (Paid annually)</p>
                                ) : (
                                    <p className='per-month'>Per Month</p>
                                )}
                            </div>
                            <p className='contract-text'>1 Year Minimum Contract</p>
                            <a href='/contact'>
                                <button className="select-button">Continue with {plan.tier.toLowerCase()} tier</button>
                            </a>

                            <ul>
                                {plan.connections.map((feature, i) => (
                                    <li key={i}>{feature}</li>
                                ))}
                            </ul>
                            <p className='divider-with'>With:</p>
                            <ul>
                                {plan.features.map((feature, i) => (
                                    <li key={i}>{feature}</li>
                                ))}
                            </ul>
                        </div>
                    ))}
                </div>

                <div className='enterprise-card'>
                    <h2>Enterprise</h2>
                    <p className='calculation-information'>
                    Enterprise by size or Enterprise by requirement - If you would prefer to customise a plan or build your own: 
                    </p>
                    <a href='/contact'>
                        <button className="select-button">Contact sales</button>
                    </a>
                </div>
            </div>
        </div>
    );
};

export default Pricing;
