import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import classes from './Articles.module.css';
import articlesData from './articles.json';

const Article = ({ title, overview, link, articleImage, showImage, date, topics }) => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const truncateOverview = (text, length) => {
        const truncated = text.substring(0, length);
        // Find the last space within the truncated string to ensure we don't cut off in the middle of a word
        const lastSpaceIndex = truncated.lastIndexOf(' ');

        // Cut the text at the last space and append the "Read more" part
        return (
            <>
                {truncated.substring(0, lastSpaceIndex)}... <span style={{color: 'var(--secondary-color)'}}>Read more</span>
            </>
        );
    };    
    

    return (
        <Link to={link} className={classes.articleLink}>
            <div className={classes.articleCard} style={{animation: 'fadeIn 1s'}}>
                {showImage && <img src={articleImage} alt="Article placeholder" className={classes.articleImage} />}
                <h3>{title}</h3>
                <p className={classes.articleCardMeta}>{date} | {topics[1]}, {topics[2]}, {topics[3]}</p>
                <p>{truncateOverview(overview, 150)}</p>
            </div>
        </Link>
    );
};


const OverviewGrid = ({ articles, sectionTitle }) => (
    <div className={classes.section}>
        <h1 style={{animation: 'fadeIn 1s'}}>{sectionTitle}</h1>
        <div className={classes.grid}>
            {articles.map((article, index) => (
                <Article
                    key={index}
                    title={article.title}
                    date={article.date}
                    topics={article.topics}
                    articleImage={article.articleImage}
                    overview={article.introduction}
                    link={`/articles/${article.id}`}
                    showImage={sectionTitle !== "Documentation"}  // Only show image if in Articles section
                />
            ))}
        </div>
    </div>
);


const ArticleOverview = () => {
    const [articles, setArticles] = useState([]);
    const [articlesSection, setArticlesSection] = useState([]);
    const [documentationSection, setDocumentationSection] = useState([]);

    useEffect(() => {
        const allArticles = articlesData.articles;  // Load all articles from JSON file

        // Filter articles based on tags
        const filteredArticles = allArticles.filter(article => article.topics.includes("Article"));
        const filteredDocumentation = allArticles.filter(article => article.topics.includes("Documentation"));

        setArticles(allArticles);  // Set all articles (if needed for other use cases)
        setArticlesSection(filteredArticles);  // Set filtered articles for "Articles" section
        setDocumentationSection(filteredDocumentation);  // Set filtered articles for "Documentation" section
    }, []);

    return (
        <div className={classes.heroBlogWrapper}>
            <div className='container' style={{minHeight: '20rem', paddingTop: '8rem'}}>
                {/* Section for Articles */}
                <OverviewGrid articles={articlesSection} sectionTitle="Articles" />
                {/* Section for Documentation */}
                <OverviewGrid articles={documentationSection} sectionTitle="Documentation" />
            </div>
        </div>
    );
};

export default ArticleOverview;
