import React from 'react';
import {
    BrowserRouter as Router,
    Routes,
    Route,
} from 'react-router-dom';
import Header from './Components/Header';
import Hero from './Components/Hero';
import Features from './Components/Features';
import FAQ from './Components/FAQ';
import Pricing from "./Components/Pricing";
import BookADemo from './Components/BookADemo';
import HeaderBlog from './Components/HeaderBlog';
import ArticleOverview from "./Articles/ArticleOverview";
import 'animate.css';
import './App.css';
import { Analytics } from "@vercel/analytics/react";
import ScrollToTop from '../src/Components/ScrollToTop';
import Footer from './Components/Footer';
import Contact from './Components/Contact';
import Company from "./Components/Company";
import Article from './Articles/Article';
import PricingFAQ from './Components/PricingFAQ';
import { HelmetProvider } from 'react-helmet-async';  // Added HelmetProvider

function Home() {
    return (
        <>
            <Header />
            <Hero />
            <Features />
            <FAQ />
        </>
    );
}

function PricingPage() {
    return (
        <>
            <Header />
            <Pricing />
            <PricingFAQ />
        </>
    );
}

function CompanyPage() {
    return (
        <>
            <Header theme='dark'/>
            <Company />
        </>
    );
}

function ContactPage() {
    return (
        <>
            <Header />
            <Contact />
        </>
    );
}

function ArticlePage() {
    return (
        <>
            <Header theme='dark'/>
            <ArticleOverview />
        </>
    );
}

function SingleArticlePage() {
    return (
        <>
            <Header />
            <Article />
        </>
    );
}

function App() {
    return (
        <HelmetProvider>  {/* Wrapping entire app with HelmetProvider */}
            <Router>
                <ScrollToTop>
                    <div className="App">
                        <Analytics />
                        <Routes>
                            <Route path="/" element={<Home />} />
                            <Route path="/temp-pricing" element={<PricingPage />} />
                            <Route path="/company" element={<CompanyPage />} />
                            <Route path="/contact" element={<ContactPage />} />
                            <Route path="/resources" element={<ArticlePage />} />
                            <Route path="/articles/:articleId" element={<SingleArticlePage />} />
                        </Routes>
                    </div>
                    <Footer />
                </ScrollToTop>
            </Router>
        </HelmetProvider>
    );
}

export default App;
