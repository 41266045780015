import React from 'react';
import './CardWithImage.css';

const CardWithImage = ({ title, subtitle, children, img, imgAlt, imagePosition = 'left' }) => {
    return (
        <div className={`cardWithImage-container ${imagePosition === 'right' ? 'card-left' : 'card-right'}`}>
            <img
                src={img}
                className={`cardWithImage-placeholder ${imagePosition === 'right' ? 'order-right' : ''}`}

            />
            <div className={`cardWithImage-content ${imagePosition === 'right' ? 'order-left' : ''}`}>
                <p className="cardWithImage-subtitle">{subtitle}</p>
                <h2 className="cardWithImage-title">{title}</h2>
                <div className="cardWithImage-body">
                    {children}
                </div>
            </div>
        </div>
    );
};

export default CardWithImage;
