import React from 'react';
import './DownloadDataSheet.css';
import downloadIcon from '../Images/download.png';

const DownloadDataSheet = ({ }) => {
    return (
        <div className="download-datasheet-container">
            <h1 className="download-data-sheet-title">Download the data sheet</h1>
            <div className="download-data-button">
                <img src={downloadIcon} className="download-icon" alt="Download" />
            </div>
        </div>
    );
};

export default DownloadDataSheet;
