import React, { useEffect, useRef } from 'react';
import './Features.css';
import CardWithImage from './CardWithImage';

import ping from '../Images/threpoly-ping.png'
import graph from '../Images/threpoly-graph.png'
import fingerprint from '../Images/threpoly-fingerprint.png'

const Features = () => {
  const sectionRefs = useRef([]); // To store refs to each article

  useEffect(() => {
    const observer = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              // Add the 'in-view' class when the article reaches the center of the viewport
              entry.target.classList.add('in-view');
            }
          });
        },
        {
          threshold: 0.5, // Trigger when 50% of the article is visible
        }
    );

    sectionRefs.current.forEach((section) => {
      if (section) observer.observe(section);
    });

    return () => {
      sectionRefs.current.forEach((section) => {
        if (section) observer.unobserve(section);
      });
    };
  }, []);

  return (
      <div className="container">
        <section className="features">
          <h1 id="features">Always Remain Open for Business</h1>
          <p className='features-overview-text'>
            Whether you use an MFT tool to allow customers to upload or download resources,<br/>have an EDI solution powering your orders and invoicing operations, or for any other use<br/>your business relies on these critical workflows and functions to trade, remain competitive and grow.
            <br/><br/>
            Threpoly gives you with insights into security and performance information, not natively available in those solutions.
          </p>

          {[
            {
              title: 'Never Be The Last to Discover Slowdowns & Outages',
              subtitle: 'PROACTIVE APPROACH',
              img: ping,
              imgAlt: 'Heartbeat testing',
              imagePosition: "left",
              content: [
                'You and your trading partners need to be confident that there will be no interruption to day-to-day operations. Outages cost time, money and reputation.',
                'Threpoly uses up-to three globally distributed heartbeat monitors to track the performance of SFTP and FTPS services over time, highlighting & notifying of patterns of slowdown or outages - before your customers and suppliers report them to you.',
              ],
            },
            {
              title: 'Diagnose Clear Problem Areas, When Problems Arise',
              subtitle: 'Intuitive Diagnostics',
              img: graph,
              imgAlt: 'Multi-factor Authentication',
              imagePosition: "right",
              content: [
                'When things go wrong, ambiguous error codes and finger-pointing between teams can be frustrating and lengthen the resolution time.',
                'Threpoly’s synthetic usage feature authenticates into your solution and simulates real-world traffic & behavior. This gives us the insight as to where the issue lies, whether it be network, protocol, application or something else.',
              ],
            },
            {
              title: 'Plan for Increased Traffic Levels Without Fear of Bottlenecks',
              subtitle: 'Business Insights',
              img: fingerprint,
              imgAlt: 'Synthetic usage and security',
              imagePosition: "left",
              content: [
                'You want to support your growing business with increased file transfer and data exchange traffic, but the strategy is to throw more-and-more at your solution and network, hoping they will continue to work…',
                'Threpoly provides a bird-eye view of performance statistics and failed connections so you can better plan for increases in traffic and be confident of the outcome.',
              ],
            },
          ].map((article, index) => (
              <article
                  key={index}
                  ref={(el) => (sectionRefs.current[index] = el)}
                  className="feature-article hidden" // Initially hidden
              >
                <CardWithImage
                    title={article.title}
                    subtitle={article.subtitle}
                    img={article.img}
                    imgAlt={article.imgAlt}
                    imagePosition={article.imagePosition}
                >
                  {article.content.map((text, i) => (
                      <p key={i}>{text}</p>
                  ))}
                </CardWithImage>
              </article>
          ))}
        </section>
      </div>
  );
};

export default Features;
