import React, { useState } from 'react';
import emailjs from 'emailjs-com';
import './Contact.css'; // Assuming the styles from BookADemo are similar

const Contact = () => {
  const [honeypot, setHoneypot] = useState('');
  const [formSubmitted, setFormSubmitted] = useState(false);

  const sendEmail = (e) => {
    e.preventDefault();

    if (honeypot) {
      console.log('Bot detected');
      return; // Pretend to submit the form
    }

    emailjs.sendForm('service_ckqip6f', 'template_diaq14e', e.target, 'elO_9EEwugoFV73pD')
      .then((result) => {
        console.log(result.text);
        setFormSubmitted(true); // Set formSubmitted to true on success
      }, (error) => {
        console.log(error.text);
        alert('Failed to send the message, please try again.');
      });

    e.target.reset();
  };

  return (
    <section id="contact" className="contact-section">
        <h1 className='page-title'>Get in touch</h1>
        <p className='page-sub-heading'>Please use our contact form for any further enquiries.</p>
      <div className="contact-grid">
        {/* Left Column with Map */}
        <div className="map-container">
          <iframe
            src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=167-169%20Great%20Portland%20Street%20London%20W1W%205PF+(Threpoly)&amp;t=&amp;z=16&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
            width="100%" 
            height="500" 
            allowFullScreen="" 
            loading="lazy" 
            referrerPolicy="no-referrer-when-downgrade"
            title="Company Location"
          ></iframe>
        </div>

        {/* Right Column with Form */}
        <div className="form-container">
          {formSubmitted ? (
            <p>Your enquiry has been successful. Our team will be in contact with more information shortly.</p>
          ) : (
            <form className="contact-form" onSubmit={sendEmail}>
              <div className="form-group">
                <input type="text" id="name" name="name" placeholder="Full name" required />
              </div>
              <div className="form-group">
                <input type="text" id="company" name="company" placeholder="Company name" required />
              </div>
              <div className="form-group">
                <input type="email" id="email" name="email" placeholder="Email" required />
              </div>
              <div className="form-group">
                <input type="tel" id="phone" name="phone" placeholder="Phone number" required />
              </div>
              <div className="form-group">
                <textarea id="message" name="message" rows="4" placeholder="Message" required></textarea>
              </div>
              {/* Honeypot field */}
              <div style={{ display: 'none' }}>
                <input
                  type="text"
                  id="honeypot"
                  name="honeypot"
                  value={honeypot}
                  onChange={(e) => setHoneypot(e.target.value)}
                  placeholder="Leave this field blank"
                />
              </div>
              <button type="submit" className="submit-button">Send</button>
            </form>
          )}
        </div>
      </div>
    </section>
  );
};

export default Contact;
