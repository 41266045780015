import React, { useEffect, useState, useRef } from 'react';
import { Helmet } from 'react-helmet-async';
import { useParams, useLocation, Link } from 'react-router-dom';
import articlesData from './articles.json';
import classes from './Articles.module.css';  // Assuming you're using CSS modules for styling

const Article = ({ updateSearchTerm }) => {
  const { articleId } = useParams();
  const location = useLocation();
  const [article, setArticle] = useState(null);  // Article is null initially
  const [readTime, setReadTime] = useState(0);
  const [scrollPosition, setScrollPosition] = useState(0);


  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.scrollY || document.documentElement.scrollTop;  // Current scroll from top
      const scrollHeight = document.documentElement.scrollHeight - window.innerHeight;  // Total scrollable height
      const scrollPercentage = (scrollTop / scrollHeight) * 100;  // Calculate scroll percentage
      setScrollPosition(scrollPercentage);
    };

    // Add scroll event listener to the window
    window.addEventListener('scroll', handleScroll);

    // Cleanup the event listener when the component unmounts
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);  // Empty dependency array to run this effect only once on mount

  useEffect(() => {
    const foundArticle = articlesData.articles.find(article => article.id === articleId);
    if (foundArticle) {
      setArticle(foundArticle);
      calculateReadTime(foundArticle);
    }
  }, [articleId]);

  const calculateReadTime = (article) => {
    const wordsPerMinute = 200;
    const text = [article.introduction, ...article.sections.map(section => section.text)].join(' ');
    const wordCount = text.split(/\s+/).length;
    const time = Math.ceil(wordCount / wordsPerMinute);
    setReadTime(time);
  };

  const generateSlug = (heading) => {
    if (!heading) {
      return '';  // Return an empty string if the heading is null or undefined
    }
    
    return heading
      .toLowerCase()
      .replace(/\s+/g, '-')  // Replace spaces with hyphens
      .replace(/[^\w\-]+/g, '');  // Remove non-alphanumeric characters (except hyphens)
  };

  const formatTextWithBullets = (text) => {
    return text.split('\n').map((line, index) => {
      // First, check for a leading dash and trim it if present
      let isBullet = false;
      if (line.startsWith('-')) {
        isBullet = true;
        line = line.substring(1).trim();  // Remove the dash and any extra spaces
      }

      // Apply bold formatting for text enclosed in **
      const formattedLine = line.split(/(\*\*.*?\*\*)/).map((part, i) => {
        if (part.startsWith('**') && part.endsWith('**')) {
          return <strong key={i}>{part.slice(2, -2)}</strong>;  // Remove ** and make strong
        }
        return part;  // Return unmodified part
      });

      // Render as a list item if it was a bullet point, otherwise as a paragraph
      if (isBullet) {
        return <li key={index}>{formattedLine}</li>;
      } else {
        return <p key={index}>{formattedLine}</p>;
      }
    });
  };

  if (!article) {
    return <div>Loading...</div>;  // Display a loading message or placeholder
  }
  
  const imageUrl = `https://threpoly.com${article.articleImage}`;

  return (
    <div className={classes.heroBlogWrapper} id='top-of-page'>
      <Helmet>
        <meta property="og:title" content={article.title} />
        <meta property="og:description" content={article.introduction} />
        <meta property="og:image" content={imageUrl} />
        <meta property="og:url" content={window.location.href} />
        <meta property="og:type" content="article" />
      </Helmet>
      <div className={classes.heroBlog}>
        <div className={classes.heroInner}>
          <Link to='/resources'><p className={classes.backLink}>◂ Back to Resources</p></Link>
          <h1 style={{ animation: 'fadeIn 1s' }} dangerouslySetInnerHTML={{ __html: article.title.replace(/\n\n/g, '<br />') }}></h1>
          <p className={classes.introduction} style={{ animation: 'fadeIn 1s' }}>{article.introduction}</p>
          <div className={classes.articleMeta} style={{ animation: 'fadeIn 1s' }}>
            <p><strong>Posted:</strong> {article.date}</p>
            <p><strong>Read time:</strong> {readTime} min</p>
            {article.topics.map((topic, index) => (
              <p key={index} className={classes.topicTag}>{topic}</p>
            ))}
          </div>
        </div>
      </div>
      
      <div className='container' style={{ animation: 'fadeIn 1s' }}>
        <div className={classes.articleWrapper}>
          <div className={classes.articleInner}>
            {article.sections 
              .map((section, index) => (
                <div key={index} id={generateSlug(section.heading)} className={classes.section}>
                  {section.heading ? <h2>{section.heading}</h2> : null}
                  {formatTextWithBullets(section.text)}
                  {section.image && <img src={section.image} alt={section.heading} className={classes.articleImage} />}
                </div>
            ))}
          </div>
          <div className={classes.sectionsOverview}>
          <div className={classes.scrollMarker} style={{ top: `${scrollPosition}%` }} />
            <h2>Overview</h2>
            <ul>
              {article.sections
                .filter(section => section.heading && section.heading.trim() !== '')  // Filter out empty or null headings
                .map((section, index) => (
                  <p key={index}>
                    <a href={`#${generateSlug(section.heading)}`}>{section.heading}</a>
                  </p>
              ))}
              <p>
                <a href={'#top-of-page'} style={{color: 'var(--text-color'}}>Scroll to top</a>
              </p>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Article;
